<template>
  <me-form class="me-grid-column-2" ref="form">
    <me-label width="300px" title="合同号" prop="scontractbuyerno">
      <me-input readonly v-model="form.scontractbuyerno" />
    </me-label>
    <me-label width="300px" title="卖方名称" prop="ssellermembername">
      <me-input readonly v-model="form.ssellermembername" />
    </me-label>
    <me-label width="300px" title="买方名称" prop="sbuyermembername">
      <me-input readonly v-model="form.sbuyermembername" />
    </me-label>
    <me-label width="300px" title="合同金额" prop="fgoodsamount">
      <me-input readonly v-model="form.fgoodsamount" />
    </me-label>
    <me-label width="300px" title="合同总额" prop="ftotalamount">
      <me-input readonly v-model="form.ftotalamount" />
    </me-label>
    <me-label width="300px" title="总重量" prop="ftotalweight">
      <me-input readonly v-model="form.ftotalweight" />
    </me-label>
    <me-label width="300px" title="发货金额" prop="fdeliverygoodsfund">
      <me-input readonly v-model="form.fdeliverygoodsfund" />
    </me-label>
    <me-label width="300px" title="发货金额" prop="fdeliveryfund">
      <me-input readonly v-model="form.fdeliveryfund" />
    </me-label>
    <me-label width="300px" title="实际交收总量" prop="fdeliveryweight">
      <me-input readonly v-model="form.fdeliveryweight" />
    </me-label>
    <me-label width="300px" title="开票金额" prop="finvoiceamount">
      <me-input readonly v-model="form.finvoiceamount" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        scontractbuyerno: '',
        ssellermembername: '',
        sbuyermembername: '',
        fgoodsamount: '',
        ftotalamount: '',
        ftotalweight: '',
        fdeliverygoodsfund: '',
        fdeliveryfund: '',
        fdeliveryweight: '',
        finvoiceamount: '',
        iinvalidatetype: '',
        iopeninvoicetype: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectContractByPrimary' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    }
  }
}
</script>
